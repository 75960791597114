body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   /* background-image: url(./images/light-1282314_1920.jpg); 
  background-size: cover;
  background-repeat: no-repeat; */
}

.header {

 background-image: url(./images/light-1282314_1920.jpg); 
  background-size: cover;
  background-repeat: no-repeat; 
  padding-top: 100px;
  padding-bottom: 100px;
  border-radius: 40px;
  border: 1px solid #f9f9fa;
 
  
}

h1, h2, h3, h4, h5, h6 {
  font-family: "PT Serif", serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type=file] {
  width: 100%;
}

/*--------------------------------------------------------------
# Landing Section
--------------------------------------------------------------*/
#main-content {
  width: 100%;
  height: 80vh;
  /* background: #fff; */
  padding-top: 100px;
}

#main-content h1 {
  margin: 0;
  font-weight: 700;
  line-height: 56px;
  color: #3e4450;
}

#main-content h2 {
  /* color: red; */
  margin: 10px 0 30px 0;
  font-weight: 700;
  font-size: 24px;
}

#main-content h3 {
  /* color: #858ea1; */
  margin: 10px 0 30px 0;
  font-size: 20px;
}

#main-content .btn-get-started {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 30px 10px 30px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  background: #16df7e;
  box-shadow: 0 8px 28px rgba(22, 223, 126, 0.45);
}

#main-content .btn-get-started:hover {
  background: #206bfb;
  box-shadow: 0 8px 28px rgba(32, 107, 251, 0.45);
}


/*--------------------------------------------------------------
# Section - this is to make spaces each section on the page
--------------------------------------------------------------*/
#section {
  /* width: 100%;
  height: 80vh; */
  /* background: #fff; */
  
}

#section .first-row {
  padding-top: 65px; 
  
}

#section .icon-box {
  padding: 20px;
  color: #5f687b;
  background: #f9f9fa;
  border-radius: 4px;
  border: 1px solid #f9f9fa;
  transition: 0.3s;
  height: 100%;
}

#section .row-gap{
  padding-top: 15px;
}

#section .title {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 18px;
}

#section .title a {
  color: #5f687b;
  transition: 0.3s;
}

#section .description {
  line-height: 24px;
  font-size: 14px;
}

#section .icon-box:hover {
  border-color: #eaecef;
}

#section .icon-box:hover .title a {
  color: #16df7e;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
 #footer {
  margin-top: 200px;
  margin-bottom: 10px;
  border-top: #444444;
  text-align: center;
  
  }
  
  #footer a {
  font-size: 30px;
  margin-right: 10px;
  margin-left: 10px;
  color: #343a40;
  }
  
  #footer hr {
    margin-top: 200px;
    margin-bottom: 20px;
    border: 0;
    border-top-color: currentcolor;
    border-top-style: none;
    border-top-width: 0px;
    border-top: 1px solid darkgray;
  }

  /*--------------------------------------------------------------
# Featured Services
--------------------------------------------------------------*/
.featured-services {
  padding: 0;
}

.featured-services .icon {
  margin: 0 0 15px 0;
}

.featured-services .icon i {
  color: #16df7e;
  font-size: 36px;
}

.featured-services .title {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 18px;
}

.featured-services .title a {
  color: #5f687b;
  transition: 0.3s;
}

.featured-services .icon-box:hover {
  border-color: #eaecef;
}

.featured-services .icon-box:hover .title a {
  color: #16df7e;
}

.featured-services .description {
  line-height: 24px;
  font-size: 14px;
}

/*--------------------------------------------------------------
# About Section
--------------------------------------------------------------*/

.about:hover a {
  color: #16df7e;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/

#main-content .hero-img {
  text-align: center;
  width: 350px;
  height: auto;
}

#main-content .btn-get-started {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 30px 10px 30px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  background: #16df7e;
  box-shadow: 0 8px 28px rgba(22, 223, 126, 0.45);
}

#main-content .btn-get-started:hover {
  background: #206bfb;
  box-shadow: 0 8px 28px rgba(32, 107, 251, 0.45);
}

#main-content .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

/*--------------------------------------------------------------
# Not Found Page
--------------------------------------------------------------*/

.NotFound {
  padding-top: 100px;
  text-align: center;
}