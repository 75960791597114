.App {
  margin-top: 15px;
}

/* making the top navigation bar's background and border invisible */
.App .navbar{
  font-weight: bold;
  background-color:transparent !important; 
  border:0;
  /* margin-left: 15px; */
}

/* .App .navbar-collapse{
  margin-right: 165px; 
  padding-top: 15px; 
  
} */

/* .App .navbar-brand{
   margin-left: 65px; 
  
} */

.nav-link {
  font-size: 15px;
}

.nav-link:hover {
  background-color: #ddd;
}

a.active.nav-link {
  font-weight: bold;
  /* background-color:lightgrey !important; */
  /* border-radius: 20px;  */
  border-color: #46b8da;
  /* text-decoration: none;*/
  /* background-color: transparent !important; */
}

.dropdown-item {
  font-size: 15px;
  /* font-weight: bold; */
}

.dropdown-item:hover {
  background-color: #ddd;
}

/* .jumbotron {
  background-color:transparent !important; 
} */

.banner-text{
  font: 90px/1.1em 'opensans-bold', sans-serif;
   letter-spacing: -2px;
   margin: 0 auto 18px auto;
}


.table thead {
  background-color: #5bc0de;
}

